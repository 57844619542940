var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home"}},[_c('header',[_c('div',{staticClass:"title"},[_vm._v("今日概况")]),(_vm.nowDayData.todayData.length)?_c('div',{staticClass:"survey"},_vm._l((_vm.nowDayData.todayData),function(item){return _c('div',{staticClass:"surveyBox",class:item.type === '血糖'
            ? 'askBox'
            : item.type === '血压'
            ? 'payBox'
            : item.type === '尿酸'
            ? 'orderNumBox'
            : 'vipBox'},[_c('div',{staticClass:"survey_first"},[_c('img',{attrs:{"src":item.type === '血糖'
                ? ' https://haha-front-end.oss-cn-hangzhou.aliyuncs.com/uPicxt.png'
                : item.type === '血压'
                ? 'https://haha-front-end.oss-cn-hangzhou.aliyuncs.com/uPicxy.png'
                : item.type === '尿酸'
                ? 'https://haha-front-end.oss-cn-hangzhou.aliyuncs.com/uPicns.png'
                : 'https://haha-front-end.oss-cn-hangzhou.aliyuncs.com/uPicxz.png',"alt":""}})]),_c('div',{staticClass:"survey_next"},[_c('div',[_vm._v(_vm._s(item.type))]),_c('div',[_vm._v(_vm._s(item.unusual)+" / "+_vm._s(item.total)+"人")])])])}),0):_c('div',[_c('el-empty',{attrs:{"description":"暂无数据"}})],1)]),_c('main',[_c('div',{staticClass:"title"},[_vm._v("累计数据")]),(_vm.nowDayData.totalData.length)?_c('div',{staticClass:"statistics"},_vm._l((_vm.nowDayData.totalData),function(item){return _c('div',{staticClass:"main_content"},[_c('div',{staticClass:"main_left"},[_c('img',{attrs:{"src":item.type === '血糖'
                ? ' https://haha-front-end.oss-cn-hangzhou.aliyuncs.com/uPicxt.png'
                : item.type === '血压'
                ? 'https://haha-front-end.oss-cn-hangzhou.aliyuncs.com/uPicxy.png'
                : item.type === '尿酸'
                ? 'https://haha-front-end.oss-cn-hangzhou.aliyuncs.com/uPicns.png'
                : 'https://haha-front-end.oss-cn-hangzhou.aliyuncs.com/uPicxz.png',"alt":""}})]),_c('div',{staticClass:"main_right"},[_c('div',{staticClass:"main_num"},[_vm._v(_vm._s(item.unusual)+" / "+_vm._s(item.total)+" 人")]),_c('div',{staticClass:"main_size"},[_vm._v(_vm._s(item.type))])])])}),0):_c('div',[_c('el-empty',{attrs:{"description":"暂无数据"}})],1)]),_c('footer',[_c('div',{staticClass:"footer_title"},[_vm._v("血糖情况统计图")]),_c('div',{ref:"myChart",staticClass:"myChartsClass"})]),_c('footer',[_c('div',{staticClass:"footer_title"},[_vm._v("血压情况统计图")]),_c('div',{ref:"myChartXY",staticClass:"myChartsClassXY"})]),_c('footer',[_c('div',{staticClass:"footer_title"},[_vm._v("尿酸情况统计图")]),_c('div',{ref:"myChartNS",staticClass:"myChartsClass"})]),_c('footer',[_c('div',{staticClass:"footer_title"},[_vm._v("血脂情况统计图")]),_c('div',{ref:"myChartXZ",staticClass:"myChartsClass"})])])}
var staticRenderFns = []

export { render, staticRenderFns }