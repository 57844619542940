import request from '../../utils/request'

// 检测日志
export function getManagePage(params: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/equipmentData/managePage?current='+params.current+'&size='+params.size+'&title='+params.title+'&status='+params.status+'&type='+params.type+'&startDate='+params.startDate+'&endDate='+params.endDate,
  })
}
// 数据统计
export function getCount(params: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/equipmentData/count',
  })
}
// 统计图表
export function getNnalyse(params: any): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/equipmentData/analyse',
    type:'params',
    data:params
  })
}


