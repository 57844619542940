

























































































import { Component, Vue } from "vue-property-decorator";

import { getCount, getNnalyse } from "@/serve/wearable/index";
@Component({
  components: {},
  name: "home",
})
export default class Home extends Vue {
  public btnSomeOne = true;
  public motherFuck = 1;
  public orderNum = "";
  public orderPay = "";
  public leftWeekData = "";
  public leftWeekDataXT = [];
  public leftWeekDataXY = [];
  public leftWeekDataNS = [];
  public leftWeekDataXZ = [];

  public weekDataXTX: any[] = [];
  public weekDataXTY1: any[] = [];
  public weekDataXTY2: any[] = [];
  public weekDataXYX: any[] = [];
  public weekDataXYY1: any[] = [];
  public weekDataXYY2: any[] = [];
  public weekDataNSX: any[] = [];
  public weekDataNSY1: any[] = [];
  public weekDataNSY2: any[] = [];
  public weekDataXZX: any[] = [];
  public weekDataXZY1: any[] = [];
  public weekDataXZY2: any[] = [];

  public leftMonthData = "";
  public nowDayData = {};
  public mook: string[] = [];
  public mookData: string[] = [];
  // echarts传入数据
  public async created() {
    await this.getList();
    await this.changeDay(this.leftWeekData);
  }
  public mounted() {
    this.getChartsXT();
    this.getChartsXY();
    this.getChartsNS();
    this.getChartsXZ();
  }
  // 获取当前页面数据
  public async getList() {
    const data = {};
    const res = await getCount(data);
    this.nowDayData = res.data;
    console.log("nowDayData", this.nowDayData);
    const params = {
      start: "",
      end: "",
    };
    const resNnalyse = await getNnalyse(params);
    console.log("具体数据", resNnalyse);
    resNnalyse.data.map((items: { type: any; data: never[] }) => {
      switch (items.type) {
        case "血糖":
          this.leftWeekDataXT = items.data;
          break;
        case "血压":
          this.leftWeekDataXY = items.data;
          break;
        case "尿酸":
          this.leftWeekDataNS = items.data;
          break;
        case "血脂":
          this.leftWeekDataXZ = items.data;
          break;
        default:
          break;
      }
    });
    this.coordinates();
    console.log("具体数据", resNnalyse);
  }
  public coordinates() {
    const {
      leftWeekDataXT,
      leftWeekDataXY,
      leftWeekDataNS,
      leftWeekDataXZ,
    } = this;
    const leftWeekDataXTX: any[] = [];
    const leftWeekDataXTY1: any[] = [];
    const leftWeekDataXTY2: any[] = [];
    const leftWeekDataXYX: any[] = [];
    const leftWeekDataXYY1: any[] = [];
    const leftWeekDataXYY2: any[] = [];
    const leftWeekDataNSX: any[] = [];
    const leftWeekDataNSY1: any[] = [];
    const leftWeekDataNSY2: any[] = [];
    const leftWeekDataXZX: any[] = [];
    const leftWeekDataXZY1: any[] = [];
    const leftWeekDataXZY2: any[] = [];
    if (leftWeekDataXT.length) {
      leftWeekDataXT.map((item: { date: any; total: any; unusual: any }) => {
        leftWeekDataXTX.push(item.date);
        leftWeekDataXTY1.push(item.total);
        leftWeekDataXTY2.push(item.unusual);
      });
    }
    if (leftWeekDataXY.length) {
      leftWeekDataXY.map((item: { date: any; total: any; unusual: any }) => {
        leftWeekDataXYX.push(item.date);
        leftWeekDataXYY1.push(item.total);
        leftWeekDataXYY2.push(item.unusual);
      });
    }
    if (leftWeekDataNS.length) {
      leftWeekDataNS.map((item: { date: any; total: any; unusual: any }) => {
        leftWeekDataNSX.push(item.date);
        leftWeekDataNSY1.push(item.total);
        leftWeekDataNSY2.push(item.unusual);
      });
    }
    if (leftWeekDataXZ.length) {
      leftWeekDataXZ.map((item: { date: any; total: any; unusual: any }) => {
        leftWeekDataXZX.push(item.date);
        leftWeekDataXZY1.push(item.total);
        leftWeekDataXZY2.push(item.unusual);
      });
    }
    this.weekDataXTX = leftWeekDataXTX
    this.weekDataXTY1 = leftWeekDataXTY1
    this.weekDataXTY2 = leftWeekDataXTY2
    this.weekDataXYX = leftWeekDataXYX
    this.weekDataXYY1 = leftWeekDataXYY1
    this.weekDataXYY2 = leftWeekDataXYY2
    this.weekDataNSX = leftWeekDataNSX
    this.weekDataNSY1 = leftWeekDataNSY1
    this.weekDataNSY2 = leftWeekDataNSY2
    this.weekDataXZX = leftWeekDataXZX
    this.weekDataXZY1 = leftWeekDataXZY1
    this.weekDataXZY2 = leftWeekDataXZY2

  }
  // 切换周月方法
  public changeDay(thisData: any) {
    const data = thisData.data;
    // this.orderNum = data.orderNum;
    // this.orderPay = data.orderPay;
    const timeArr = [];
    const valueArr = [];
    for (const key in data) {
      timeArr.push(data[key].date);
      valueArr.push(data[key].count);
    }
    this.mook = timeArr; // x轴数据
    this.mookData = valueArr; // y轴值

    this.getChartsXT();
    this.getChartsXY();
    this.getChartsNS();
    this.getChartsXZ();
  }
  // echart 的方法
  public getChartsXT(): void {
    const myChart = this.$echarts.init(this.$refs.myChart as HTMLCanvasElement);
    myChart.setOption({
      legend: {
        show: true,
      },
      toolbox: {},
      title: {
        // text: '营收统计(元)',
        textStyle: {
          fontSize: 15,
          fontWeight: 500,
        },
      },
      tooltip: {},
      xAxis: {
        name: "日期",
        data: this.weekDataXTX,
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        yxisTick: {
          show: false,
        },
      },
      series: [
        {
          type: "line",
          data: this.weekDataXTY1,
          color: "#38A28A",
          symbolSize: 10,
        },
        {
          type: "line",
          data: this.weekDataXTY2,
          color: "#ff6666",
          symbolSize: 10,
        },
      ],
    });
    setTimeout(function() {
      window.onresize = function() {
        myChart.resize();
      };
    }, 200);
  }
  public getChartsXY(): void {
    const myChart = this.$echarts.init(
      this.$refs.myChartXY as HTMLCanvasElement
    );
    myChart.setOption({
      legend: {
        show: true,
      },
      toolbox: {},
      title: {
        // text: '营收统计(元)',
        textStyle: {
          fontSize: 15,
          fontWeight: 500,
        },
      },
      tooltip: {},
      xAxis: {
        name: "日期",
        data: this.weekDataXYX,
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        yxisTick: {
          show: false,
        },
      },
      series: [
        {
          type: "line",
          data: this.weekDataXYY1,
          color: "#38A28A",
          symbolSize: 10,
        },
        {
          type: "line",
          data: this.weekDataXYY2,
          color: "#ff6666",
          symbolSize: 10,
        },
      ],
    });
    setTimeout(function() {
      window.onresize = function() {
        myChart.resize();
      };
    }, 200);
  }

  public getChartsNS(): void {
    const myChart = this.$echarts.init(
      this.$refs.myChartNS as HTMLCanvasElement
    );
    myChart.setOption({
      legend: {
        show: true,
      },
      toolbox: {},
      title: {
        // text: '营收统计(元)',
        textStyle: {
          fontSize: 15,
          fontWeight: 500,
        },
      },
      tooltip: {},
      xAxis: {
        name: "日期",
        data: this.weekDataNSX,
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        yxisTick: {
          show: false,
        },
      },
      series: [
        {
          type: "line",
          data: this.weekDataNSY1,
          color: "#38A28A",
          symbolSize: 10,
        },
        {
          type: "line",
          data: this.weekDataNSY2,
          color: "#ff6666",
          symbolSize: 10,
        },
      ],
    });
    setTimeout(function() {
      window.onresize = function() {
        myChart.resize();
      };
    }, 200);
  }

  public getChartsXZ(): void {
    const myChart = this.$echarts.init(
      this.$refs.myChartXZ as HTMLCanvasElement
    );
    myChart.setOption({
      legend: {
        show: true,
      },
      toolbox: {},
      title: {
        // text: '营收统计(元)',
        textStyle: {
          fontSize: 15,
          fontWeight: 500,
        },
      },
      tooltip: {},
      xAxis: {
        name: "日期",
        data: this.weekDataXZX,
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        yxisTick: {
          show: false,
        },
      },
      series: [
        {
          type: "line",
          data: this.weekDataXZY1,
          color: "#38A28A",
          symbolSize: 10,
        },
        {
          type: "line",
          data: this.weekDataXZY2,
          color: "#ff6666",
          symbolSize: 10,
        },
      ],
    });
    setTimeout(function() {
      window.onresize = function() {
        myChart.resize();
      };
    }, 200);
  }
}
